import { clsx } from "clsx";
import { JSXElementConstructor } from "react";
import {
  IntersectionOptions,
  IntersectionObserverProps,
  InView,
} from "react-intersection-observer";

type Props = IntersectionOptions & {
  children?: React.ReactNode | IntersectionObserverProps["children"];
  className?: string | (({ inView }: { inView: Boolean }) => string);
  as?: keyof JSX.IntrinsicElements | JSXElementConstructor<any>;
  [key: string]: any;
};

const InViewSelf: React.FC<Props> = ({
  className,
  children,
  as: Wrapper = "div",
  root,
  rootMargin,
  threshold,
  triggerOnce,
  skip,
  initialInView,
  fallbackInView,
  trackVisibility,
  delay,
  onChange,
  ...props
}) => {
  return (
    <InView
      {...{
        root,
        rootMargin,
        threshold,
        triggerOnce,
        skip,
        initialInView,
        fallbackInView,
        trackVisibility,
        delay,
        onChange,
      }}
    >
      {({ inView, ref, entry }) => (
        <Wrapper
          {...props}
          ref={ref}
          className={clsx(
            inView && "in-view",
            typeof className === "function" ? className({ inView }) : className,
          )}
          children={
            typeof children === "function"
              ? children({ inView, ref, entry })
              : children
          }
        />
      )}
    </InView>
  );
};

export default Object.assign(InView, { Self: InViewSelf });
